*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  /* font-family: Helvetica, Arial, sans-serif; */
  font-size: 100%;
  /* background: #333; */
}

body {
  margin: 0;
  font-family: "Quicksand", Arial, Helvetica, sans-serif;
}

.bgTransparent {
  opacity: 0;
  height: 79.33vh;
}

.container {
  height: 20.67vh;
  /* height: 100vh; */
  padding: 5% 5% 16%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
}

.footer {
  display: flex;
  align-items: flex-end;
  position: absolute;
  right: 5%;
  bottom: 6%;
}

.titleMessage {
  font-style: normal;
  font-weight: bold;
  font-size: 5vw;
  color: #70ce28;
  margin-left: 4vw;
  text-transform: uppercase;
}

.logoTupi {
  width: 12vw;
  margin-left: 5vw;
  align-self: center;
}

.logoEffix {
  width: 16vw;
  margin-left: 5vw;
}

.icon {
  width: 8.7vw;
}

.qrcode {
  width: 130px;
  margin-right: 50px;
}

.message {
  font-style: normal;
  font-weight: 500;
  font-size: 3.2vw;
  color: #22263f;
  line-height: 125%;
  letter-spacing: 0.01vh;
}

.indicator {
  position: absolute;
  right: 1.1vw;
  top: 1.1vw;
  width: 1.1vw;
  height: 1.1vw;
  opacity: 0.3;
  background-color: #70ce28;
  border-radius: 10px;
}

.lds-dual-ring {
  display: inline-block;
  width: 106px;
  height: 106px;
  margin-right: 50px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 90px;
  height: 90px;
  margin: 8px;
  border-radius: 50%;
  border: 7px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.rowMessage {
  padding-left: 13vw;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
